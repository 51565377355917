import Student from "Models/Student";
import StudentFilter from "Models/StudentFilter";
import StudentMap from "Models/StudentMap";
import TeamFormation from "Models/TeamFormation";
import Tsv from "Models/Tsv";

export abstract class BaseClearUnitTsvRow {
	team: TeamFormation;
	hasTeamData() {
		return true;
	}
	getStrikerCount = () => 4;
	getSpecialCount = () => 2;
	findStudentById(student: string) {
		let s = new StudentMap().getProperName(student) ?? "EMPTY";
		if (this.team.getNames().indexOf(s) >= 0)
			return true;
		
		return false;
	}
	findStudent(student: StudentFilter) {
		for(const member of this.team.strikers) {
			if (student.matches(member))
				return true;
		}
		for(const member of this.team.specials) {
			if (student.matches(member))
				return true;
		}

		return false;
	}

	getIcon() {
		return "/img/DefaultStudent.jpg";
	}

	toString() {
		return `${this.getDisplayName()}\Team: ${this.team}`;
	}

	getTierIcon() {
		return `/img/Raid_RankIcon_0${this.getTierNumber()}.png`;
	}

	abstract getDisplayName(): string;
	abstract getTier(): string;
	abstract getTierNumber(): number;
}

export class ClearUnitTsvRow extends BaseClearUnitTsvRow {
	getDisplayName() {
		return `Clear unit`;
	}

	static parse(raw:string) {
		const data = raw.split('\t');
		const row = new ClearUnitTsvRow();
		let i=0;
		row.team = new TeamFormation(
			[
				Student.fromRaidRanks(data[i++]),
				Student.fromRaidRanks(data[i++]),
				Student.fromRaidRanks(data[i++]),
				Student.fromRaidRanks(data[i++]),
			],
			[
				Student.fromRaidRanks(data[i++]),
				Student.fromRaidRanks(data[i++]),
			]
		)

		return row;
	}
	
	getTier() {
		return "Platinum";
	}

	getTierNumber() {
		return 4;
	}
}

export class JfdStageTsvRow extends ClearUnitTsvRow {
	stage:number;
	
	static parse(raw:string) {
		const row:JfdStageTsvRow = super.parse(raw) as JfdStageTsvRow;

		return row;
	}
}

export class TowerFloorTsvRow extends BaseClearUnitTsvRow {
	override getStrikerCount = () => 4;
	override getSpecialCount = () => 2;

	floor: number;
	getDisplayName() {
		return `Floor ${this.floor}`;
	}

	static parse(raw:string) {
		const data = raw.split('\t');
		const row = new TowerFloorTsvRow();
		let i=0;
		row.team = new TeamFormation(
			[
				Student.fromRaidRanks(data[i++]),
				Student.fromRaidRanks(data[i++]),
				Student.fromRaidRanks(data[i++]),
				Student.fromRaidRanks(data[i++]),
				Student.fromRaidRanks(data[i++]),
				Student.fromRaidRanks(data[i++]),
			],
			[
				Student.fromRaidRanks(data[i++]),
				Student.fromRaidRanks(data[i++]),
				Student.fromRaidRanks(data[i++]),
				Student.fromRaidRanks(data[i++]),
			]
		)

		return row;
	}
	
	getTier() {
		if (this.floor < 25)
			return "Bronze";
		if (this.floor < 50)
			return "Silver";
		if (this.floor < 75)
			return "Gold";
		return "Platinum";
	}

	getTierNumber() {
		switch (this.getTier()) {
			case "Bronze": return 1;
			case "Silver": return 2;
			case "Gold": return 3;
			case "Platinum": return 4;
		}
	}
}

export class JfdStageTsv extends Tsv<JfdStageTsvRow> {
	// rows: TowerFloorTsvRow[] = [];
	parse(data:string):JfdStageTsv {
		// const tsv = new TowerFloorTsv();
		const rows = data.trim().split('\n');
		for (const row of rows) {
			try {
				this.rows.push(JfdStageTsvRow.parse(row));
			} catch (e) {}
		}
		this.raw = data;

		return this;
	}
	parseWith(data:string, extra: {
		floor: number
	}):JfdStageTsv {
		// const tsv = new TowerFloorTsv();
		const rows = data.trim().split('\n');
		for (const row of rows) {
			try {
				const parsed = JfdStageTsvRow.parse(row);
				parsed.stage = extra.floor;
				this.rows.push(parsed);
			} catch (e) {}
		}
		this.raw = data;

		return this;
	}
}
export class TowerFloorTsv extends Tsv<TowerFloorTsvRow> {
	// rows: TowerFloorTsvRow[] = [];
	parse(data:string):TowerFloorTsv {
		// const tsv = new TowerFloorTsv();
		const rows = data.trim().split('\n');
		for (const row of rows) {
			try {
				this.rows.push(TowerFloorTsvRow.parse(row));
			} catch (e) {}
		}
		this.raw = data;

		return this;
	}
	parseWith(data:string, extra: {
		floor: number
	}):TowerFloorTsv {
		// const tsv = new TowerFloorTsv();
		const rows = data.trim().split('\n');
		for (const row of rows) {
			try {
				const parsed = TowerFloorTsvRow.parse(row);
				parsed.floor = extra.floor;
				this.rows.push(parsed);
			} catch (e) {}
		}
		this.raw = data;

		return this;
	}
}