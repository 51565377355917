import RaidScore from "Models/RaidScore";
export const BUCKET_HOST = "https://ba-bucket.midokuni.com/public"
export const KEY = {
	URL: "url",
	DEBUG: "debug",
	BANNER: "Banner",
	FARMABLE: "Farmable",
	BORROWS: "Borrows",
	THOUGHTS: "Thoughts",
	STOKKIE_RAID: "StokkieRaidRating",
	CAUSEW_TOWER50: "CausewTower50",
	BRICC_ARCHIVE: "Bricc",
}

export const BOND_EMBLEM_TIERS = {
	"1": "20",
	"2": "50",
	"3": "100",
}
export const POTENTIAL_EMBLEM_TIERS = {
	"1": "25",
	"2": "Unknown"
}
class RaidScoreCalculators {
	private calculators: RaidScore[] = [];
	setCalculators(c: RaidScore[]) {
		this.calculators = c;
	}

	getCalculators() {
		return this.calculators;
	}
}

export const SCORE_CALCULATORS = new RaidScoreCalculators();

export const GOOGLE_SPREADSHEET_ID = "1BO-Uh_CsAOIetPjrNyFy9-7r1a4RAtVwK135bBC3hzM" 
export const GOOGLE_SPREADSHEET_GID = {
	BANNER: "1085051345",
	FARMABLE: "2050619277",
	BORROW: "647764595",
	THOUGHTS: "1870179352",
	STUDENTS: "0" // Yes really.
};
export const STOKKIE_RAID_SPREADSHEET_ID = "1VSzm_ioCt2AdRvkfL9HKNNZSbyslayVERXxVxR6nzh4"
export const STOKKIE_RAID_SPREADSHEET_GID = {
	TOWER_F50: "1636834237",
	STRIKER_EXT: "788945838",
	SPECIAL_EXT: "549396826",
	STRIKER_INS: "1471123612",
	SPECIAL_INS: "1422489374",
}
export const BRICC_ARCHIVE_SPREADSHEET_ID = "1-lBJa3h4mXHkmuSKohoHe60WVVg1eRG9bA73jHqHzwA"
export const BRICC_ARCHIVE_SPREADSHEET_GID = {
	STRIKER: "1940133283",
	SPECIAL: "1685181943",
}
export const SPACE_REGEX = /([_\+])|(%20)/g;

export const RAID_MINSCORE = {
	TOR: 28060000,
	INS: 15640000,
	EXT: 9200000,
	HC: 4216000,
	VH: 2108000,
	H: 1054000,
	N: 0,
}
export const RAID_MAXSCORE = {
	TOR: Number.MAX_SAFE_INTEGER,
	INS: RAID_MINSCORE.TOR-1,
	EXT: RAID_MINSCORE.INS-1,
	HC: RAID_MINSCORE.EXT-1,
	VH: RAID_MINSCORE.HC-1,
	H: RAID_MINSCORE.VH-1,
	N: RAID_MINSCORE.H-1,
}