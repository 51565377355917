import { BUCKET_HOST } from "Constants/Common";
import React, { useEffect, useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { getJson } from "Utils/Common";

interface IFileList {
	[name: string]: IFileList
}
interface IProps {
	type?: string;
}
export default function RaidDataBridge({type}:IProps) {
	const {server} = useParams();
	const fileListPath = BUCKET_HOST+'/files/data/fileList.json';
	const [fileList, setFileList] = useState<IFileList>(null);
	const [inputServer, setServer] = useState(server?.toLowerCase() ?? "");
	const [season, setSeason] = useState("");
	const serverKey = inputServer.toLowerCase();
	const serverName = inputServer.toUpperCase();
	async function loadData() {
		setFileList((await getJson(fileListPath))[type ?? "raid"]);
	}
	const typePath = (function () {
		switch (type) {
			case "grandRaid": return "GrandRaid";
			case "lbraid": return "Tower";
			case "jfd": return "JFD";
			default: return "Raid";
		}
	})();
	useEffect(() => {
		loadData();
	}, []);

	return (
		<>
		<Alert>Hello, I just made this as a bridge and little to no effort has been placed here. Use this page to select the server and season you want to view</Alert>
			{!!!server &&<Form.Select onChange={(event) => {
				setServer(event?.target?.value ?? "");
			}}>
				<option>Select Server</option>
				{Object.keys(fileList ?? {}).map(file => {
					return (<option key={file.toUpperCase()} value={file.toUpperCase()}>{file.toUpperCase()}</option>);
				})}
			</Form.Select>}
			{!!server && <a href={`/${typePath}`}>&lt;&lt;&nbsp;</a>}
			{!!server && `${serverName} Server`}
			{ fileList && inputServer !== "" && (<Form.Select onChange={(event) => {
				setSeason(event?.target?.value ?? "");
			}}>
				<option>Select Season</option>
				{Object.keys(fileList[serverKey]  ?? {}).map(file => {
					return (<option key={file} value={file}>{file} - {fileList[serverKey][file]["info.json"]?.toString() ?? "Unknown Raid"}</option>);
				})}
			</Form.Select>)}
			{ season !== "" && inputServer !== "" && (
				<Button href={`/${typePath}/${serverName}/${season}`}>Go to {serverName} S{season}</Button>
			)}
		</>
	);

}