import MultiStudentPicker from "Components/MultiStudentPicker";
import StudentFilterItem from "Components/StudentFilterItem";
import { IArmorType, IRaidRankFilter } from "Interfaces/IRaidRankFilter";
import IStyle from "Interfaces/IStyle";
import StudentFilter from "Models/StudentFilter";
import { IDifficulty, IRaidTier } from "Views/RaidData";
import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";

const style:IStyle = {
	filterRow: {marginTop: 10}
}
interface IProps {
	filters: IRaidRankFilter;
	setFilters: React.Dispatch<React.SetStateAction<IRaidRankFilter>>;
	updateView: () => void;
}
export default function GrandRaidDataFilters({filters, setFilters, updateView}:IProps) {
	const [isAdvanced, setIsAdvanced] = useState(false);
	return (
		<div className="filters">
			<Row style={style.filterRow}>
				<Col md={2}>
					<label>Armor</label>
				</Col>
				<Col>
					<Form.Select onChange={(event) => {
						setFilters({
							...filters,
							armorType: event.target.value as IArmorType
						})
					}}>
						{["ALL", "RED", "YELLOW", "BLUE", "PURPLE"].map(opt => {
							return (<option key={opt} value={opt}>{opt}</option>);
						})}
					</Form.Select>
				</Col>
			</Row>
			<Row style={style.filterRow}>
				<Col md={2}>
					<label>Difficulty</label>
				</Col>
				<Col>
					<Form.Select onChange={(event) => {
						if (event.target.value.length) {
							setFilters({
								...filters,
								minDifficulty: event.target.value as IDifficulty
							})
						} else {
							delete filters.minDifficulty;
							setFilters({
								...filters
							})
						}
					}}>
						<option key="default" value="">Select Min Difficulty</option>
						{["TOR", "INS", "EXT", "HC", "VH", "H", "N"].map(opt => {
							return (<option key={opt} value={opt}>{opt}</option>);
						})}
					</Form.Select>
				</Col>
				<Col xs={1}>-</Col>
				<Col>
					<Form.Select onChange={(event) => {
						if (event.target.value.length) {
							setFilters({
								...filters,
								maxDifficulty: event.target.value as IDifficulty
							})
						} else {
							delete filters.maxDifficulty;
							setFilters({
								...filters
							})
						}
					}}>
						<option key="default" value="">Select Max Difficulty</option>
						{["TOR", "INS", "EXT", "HC", "VH", "H", "N"].map(opt => {
							return (<option key={opt} value={opt}>{opt}</option>);
						})}
					</Form.Select>
				</Col>
			</Row>
			<Row style={style.filterRow}>
				<Col md={2}>
					<label>Score</label>
				</Col>
				<Col>
					<input 
						style={{width:"100%"}}
						type="number"
						placeholder="Min Score (1)"
						step={2000000}
						onChange={(event)=>{
							event.target.value = event.target.value.replaceAll(/[^\d]/g,"");
							if (event.target.value.length) {
								const minScore = parseInt(event.target.value);
								setFilters({
									...filters,
									minScore: minScore
								})
							} else {
								delete filters.minScore
							}
						}}
						>
					</input>
				</Col>
				<Col xs={1}>-</Col>
				<Col>
					<input 
						style={{width:"100%"}}
						type="number"
						placeholder="Max Score (999999999)"
						step={2000000}
						onChange={(event)=>{
							event.target.value = event.target.value.replaceAll(/[^\d]/g,"");
							if (event.target.value.length) {
								const maxScore = parseInt(event.target.value);
								setFilters({
									...filters,
									maxScore: maxScore
								})
							} else {
								delete filters.maxScore
							}
						}}
						>
					</input>
				</Col>
			</Row>
			<Row style={style.filterRow}>
				<Col md={2}>
					<label>Rank</label>
				</Col>
				<Col>
					<input 
						style={{width:"100%"}}
						type="number"
						placeholder="Top Rank (1)"
						step={1000}
						onChange={(event)=>{
							event.target.value = event.target.value.replaceAll(/[^\d]/g,"");
							if (event.target.value.length) {
								const minRank = parseInt(event.target.value);
								setFilters({
									...filters,
									minRank: minRank
								})
							} else {
								delete filters.minRank
							}
						}}
						>
					</input>
				</Col>
				<Col xs={1}>-</Col>
				<Col>
					<input 
						style={{width:"100%"}}
						type="number"
						placeholder="Bottom Rank (9999999)"
						step={1000}
						onChange={(event)=>{
							event.target.value = event.target.value.replaceAll(/[^\d]/g,"");
							if (event.target.value.length) {
								const maxRank = parseInt(event.target.value);
								setFilters({
									...filters,
									maxRank: maxRank
								})
							} else {
								delete filters.maxRank
							}
						}}
						>
					</input>
				</Col>
			</Row>
			<Row style={style.filterRow}>
				<Col md={2}>
					<label>Tier</label>
				</Col>
				<Col>
					<Form.Select onChange={(event) => {
						if (event.target.value.length) {
							setFilters({
								...filters,
								tiers: [event.target.value as IRaidTier]
							})
						} else {
							delete filters.tiers;
							setFilters({
								...filters
							})
						}
					}}>
						<option key="default" value="">Select Tier</option>
						{["Platinum", "Gold", "Silver", "Bronze"].map(opt => {
							return (<option key={opt} value={opt}>{opt}</option>);
						})}
					</Form.Select>
				</Col>
			</Row>
			<Row style={style.filterRow}>
				<Col md={2}>
					<label>Include</label>
				</Col>
				<Col>
					{filters?.includeStudents?.map((student, index)=>(
						<StudentFilterItem 
						key={index}
						isAdvanced={isAdvanced}
						bg="violet"
						className="list-item student include"
						student={student}
						id={index}
						onClose={()=>{
							filters.includeStudents.splice(index, 1);
							setFilters({...filters});
						}}></StudentFilterItem>
					))}

					<MultiStudentPicker 
						setTarget={(toSet:StudentFilter[]) => {
							setFilters({...filters, includeStudents: toSet ?? []});
						}} 
						target={filters.includeStudents}
						pop={() => {
							const r =filters.includeStudents.pop()
							setFilters({...filters});

							return r;
						}}
						push={(toAdd:string) => {
							const studentFilter = new StudentFilter(toAdd);
							filters.includeStudents.push(studentFilter);
							filters.includeStudents = [...new Set(filters.includeStudents)];
							setFilters({...filters});
						}}
					/>
				</Col>
			</Row>
			<Row style={style.filterRow}>
				<Col md={2}>
					<label>Exclude</label>
				</Col>
				<Col>
					{filters?.excludeStudents?.map((student, index)=>(
						<StudentFilterItem 
						key={index}
						bg="pink-border"
						isAdvanced={isAdvanced}
						className="list-item student badge-outlined"
						student={student}
						id={index}
						onClose={()=>{
							filters.excludeStudents.splice(index, 1);
							setFilters({...filters});
						}}></StudentFilterItem>
					))}
					<MultiStudentPicker 
						setTarget={(toSet:StudentFilter[]) => {
							setFilters({...filters, excludeStudents: toSet ?? []});
						}} 
						target={filters.excludeStudents}
						pop={() => {
							const r =filters.excludeStudents.pop()
							setFilters({...filters});

							return r;
						}}
						push={(toAdd:string) => {
							const studentFilter = new StudentFilter(toAdd);
							filters.excludeStudents.push(studentFilter);
							// filters.excludeStudents = [...new Set(filters.excludeStudents)];
							setFilters({...filters});
						}}
					/>
				</Col>
			</Row>
			<Row style={style.filterRow}>
				<Col md={2}>
					<label>Sensei</label>
				</Col>
				<Col>
					<input type="text" onChange={(e) => {
						if (e.target.value?.length) {
							setFilters({
								...filters,
								sensei: e.target.value
							})
						} else {
							delete filters.sensei;
							setFilters({...filters});
						}
					}}></input>
				</Col>
			</Row>
			<Row style={style.filterRow}>
				<Col md={2}>
					<label>Title</label>
				</Col>
				<Col>
					<input type="text" onChange={(e) => {
						if (e.target.value?.length) {
							setFilters({
								...filters,
								title: e.target.value
							})
						} else {
							delete filters.title;
							setFilters({...filters});
						}
					}}></input>
				</Col>
			</Row>
			<Row style={style.filterRow}>
				<Col md={2}>
				</Col>
				<Col>
					<div key="advanced-student-filter-switch" className="mb-3">
						<Form.Check 
						type="switch"
						id="advanced-student-filter-switch"
						label="Show Advanced Student Options? (This is ugly. Applied even if Hidden)"
						onChange={(a)=>{
							setIsAdvanced(a.target.checked);
						}}
						/>
					</div>
				</Col>
			</Row>
			<Row>
				<Col md={2}>
				</Col>
				<Col>
					<div key="full-teams-switch" className="mb-3">
						<Form.Check 
						type="switch"
						id="full-teams-switch"
						label="Only Include Full Units (Required for Full Analytics)"
						onChange={(a)=>{
							filters.fullTeamsOnly = a.target.checked;
							setFilters(filters);
							updateView();
							// setDisplayedData(filteredData());
						}}
						/>
					</div>
				</Col>
			</Row>
		</div>
	)
}