import TowerFloorRowRenderer from "Components/ModelRenderers/TowerFloorRowRenderer";
import MultiStudentPicker from "Components/MultiStudentPicker";
import MyLoadingSpinner from "Components/MyLoadingSpinner";
import MyPagination from "Components/MyPagination";
import RaidTerrainImage from "Components/RaidTerrainImage";
import StudentFilterItem from "Components/StudentFilterItem";
import { TagBadge } from "Components/TagBadge";
import { TowerFloorFileListDropDown } from "Components/TowerFloorFileListDropDown";
import { BUCKET_HOST, SCORE_CALCULATORS } from "Constants/Common";
import IStyle from "Interfaces/IStyle";
import RaidReportModal from "Modals/RaidReportModal";
import { TowerFloorTsv, TowerFloorTsvRow } from "Models/ClearUnitTsv";
import RaidScore from "Models/RaidScore";
import Student from "Models/Student";
import StudentFilter from "Models/StudentFilter";
import RaidReportItem from "Reports/RaidReportItem";
import RaidStudentReportItem from "Reports/RaidStudentReportItem";
import RaidTeamReportItem from "Reports/RaidTeamReportItem";
import CommonRaidReportItem from "Reports/ScoreReportItem";
import { getJson } from "Utils/Common";
import { saveAs } from 'file-saver';
import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { IRaidRankFilter } from "../Interfaces/IRaidRankFilter";

const IS_ADVANCED = 'midokuni.raid.filter.isAdvanced';
const style:IStyle = {
	button: {marginRight: 5},
	filterRow: {marginTop: 10},
}
interface IRaidInfo {
	season: number,
	playable: {[name:string]: any},
	start: string,
	end: string,
	atk: string,
	def: string,
	seasonType: any
}
function WarningMessage() {
	return (
		<Alert variant="warning" dismissible>
			{/* This is still mainly WIP. I plan to make this as functional as the Total and Grand Assault Leaderboards.
			<br></br>
			<br></br> */}
			Due to the nature of the teams listing and timings, there might be some teams that exist but are not listed here.
		</Alert>
	)
}
// export let RAID_DATA_SCORE_CALCULATORS:RaidScore[];
export default function TowerAssaultData() {
	const [data, setData] = useState<TowerFloorTsv>(null);
	const [isReportVisible, setIsReportVisible] = useState(false);
	const [displayedData, setDisplayedData] = useState<TowerFloorTsvRow[]>([]);
	const [displayedParticipationReport, setDisplayedParticipationReport] = useState<RaidStudentReportItem[]>([]);
	const [studentDataReport, setStudentDataReport] = useState<RaidStudentReportItem[]>([]);
	const [teamReport, setTeamReport] = useState<RaidTeamReportItem[]>([]);
	const [scoreReport, setScoreReport] = useState<CommonRaidReportItem>(null);
	const {server, season} = useParams();
	const [floor, setFloor] = useState<string>("");
	const [isLoading, setIsLoading] = useState(false);
	const [compact, setIsCompact] = useState(true);
	const [areAllTeamsFull, setAreAllTeamsFull] = useState(false);
	const [isFilterInverted, setisFilterInverted] = useState(false);
	const storageIsAdvanced = localStorage.getItem(IS_ADVANCED);
	const [isAdvanced, setIsAdvanced] = useState(storageIsAdvanced == null || "true" == storageIsAdvanced);

	const [filters, setFilters] = useState<IRaidRankFilter>({
		includeStudents: [],
		excludeStudents: [],
		fullTeamsOnly: false
	});
	const [info, setInfo] = useState<IRaidInfo>({
		season: -1,
		playable: { unknown: 0},
		start: "0",
		end: "0",
		atk: "",
		def: "",
		seasonType: -1
	});


	const keys = Object.keys(info.playable);
	const raidName = keys[0]?.split("_")[0] ?? "";
	const root = BUCKET_HOST+`/files/data/lbraid/${server.toLocaleLowerCase()}/${season.toString()}`;
	SCORE_CALCULATORS.setCalculators(RaidScore.fromRaid(server, parseInt(season), raidName));


	function processRaidReport() {
		// let scores:number[] = [],
		// 	times:number[] = [],
		// 	timesCount:{[diff:string|number]: number[]} = {},
		// 	timesMinMax:number[],
		// 	levels:{[level:number]:number} = {},
		// 	teamsUsed:number[] = [],
		// 	displayedScores:number[] = []
		// 	;
		
		// for (const player of data.rows) {
		// 	const diff = player.getDifficulty();
		// 	timesCount[diff] = timesCount[diff] ?? initializeTimekeeping();
		// 	scores.push(player.score);
		// 	const timeScore = SCORE_CALCULATORS.getCalculators()[player.getDifficultyNumber()].calculateSecondsElapsed(player.score);
		// 	times.push(timeScore);
		// 	const timeId = Math.floor(timeScore);
		// 	timesCount[diff][timeId]++;
		// 	if (timesMinMax) {
		// 		timesMinMax[0] = Math.min(timeId, timesMinMax[0]);
		// 		timesMinMax[1] = Math.max(timeId, timesMinMax[1]);
		// 	} else {
		// 		timesMinMax = [timeId,timeId];
		// 	}
		// 	levels[player.level] = (levels[player.level] ?? 0) + 1;
		// }
		// for (const player of displayedData) {
		// 	displayedScores.push(player.score);
		// 	teamsUsed.push(player.teams?.length??0)
		// }
		
		// setScoreReport(new CommonRaidReportItem(scores,timesCount,timesMinMax,times, levels, teamsUsed, displayedScores))
	}

	function hasFilters() {
		return filters && (
			filters.includeStudents?.length || 
			filters.excludeStudents?.length
		)
	}

	function filteredData(toFilter?: TowerFloorTsv) {
		if(hasFilters()) {
			setIsLoading(true);
			// return data?.rows?.filter(applyFilter);
			const filtered = [];
			for (const row of (toFilter ?? data)?.rows ?? []) {
				if (applyFilter(row) !== isFilterInverted)
					filtered.push(row);
			}
			
			setIsLoading(false);
			return filtered;
		}

		return (toFilter ?? data)?.rows ?? [];
	}

	useEffect(() => {
		let ignore = false;
		async function loadInfo() {
			const result = await getJson(`${root}/info.json`);
			if (!ignore) {
				setInfo(result);
			}
		}

		loadInfo();

		return () => {
			ignore = true
		}
	}, [server, season])

	useEffect(() => {
		let ignore = false;
		async function loadData() {
			if (!!!floor || "Select Data Source" === floor) return;
			setIsLoading(true);
			const temp = new TowerFloorTsv();
			const getTsv = temp.fromUrlWith(`${root}/${floor}.tsv`, {
				floor: parseInt(floor.split('-')[0])
			});
			const rawTsv = await getTsv;
			if (!ignore) {
				setData(rawTsv);
				setDisplayedData(rawTsv.rows);
				// setDisplayedData(filteredData(rawTsv));
			}
			setIsLoading(false);
			
		}

		loadData();

		return () => {
			ignore = true;
		}
	}, [floor])

	function handleChange(event) {
		if (!!!event.target.value)
			return;
		setFloor(event.target.value);
	}

	function applyFilter(row: TowerFloorTsvRow) {
		if (filters.includeStudents && filters.includeStudents.length > 0) {
			for(const student of filters.includeStudents) {
				if (!row.findStudent(student))
					return false;
			}
		}
		if (filters.excludeStudents && filters.excludeStudents.length > 0) {
			for(const student of filters.excludeStudents) {
				if (row.findStudent(student))
					return false;
			}
		}
		return true;
	}

	const getExportData = () => {
		return [];
		// if (!!!displayedData.length) {
		// 	return [];
		// }
		// const exportData = [displayedData[0].getHeaders().join("\t")+"\n"];
		// for (const row of displayedData) {
		// 	const rowData = row.export();
		// 	for (const teamData of rowData) {
		// 		exportData.push(teamData.join("\t")+"\n");
		// 	}
		// }
		// return exportData;
	}

	// TODO: thread worker this so it isn't blocking
	const generateAnalytics = () => {
		const totalPlayers = displayedData.length;
		RaidReportItem.totalPlayers = totalPlayers;
		// if (filters.fullTeamsOnly) {
			let attendanceCount = {},
			// assistantCount = {},
			// senseisPerStudent = {},
			teamCount = {},
			teams = {},
			// totalTeams = 0,
			students:{[id:string|number]: Student} = {}
			// studentScores:{[id:string|number]: number[]} = {},
			// studentTeamsUsed:{[id:string|number]: number[]} = {},
			// teamTeamsUsed:{[id:string|number]: number[]} = {},
			// teamScores:{[id:string|number]: number[]} = {},
			// studentScatter: {[id:string|number]: IPoint[]} = {},
			// studentAssistScatter: {[id:string|number]: IPoint[]} = {}
			;
			for (const player of displayedData) {
				let hasAssist = false,
					hasNoEmpty = true;
		// 		const playerStudents = [];
				// for (let i=0; i<player.team.length; i++) {
					const team = player.team;
					const teamKey = team.asKey();
					teams[teamKey] = team;
					teamCount[teamKey] = (teamCount[teamKey] ?? 0) + 1;
		// 			teamTeamsUsed[teamKey] = teamTeamsUsed[teamKey] ?? [];
		// 			teamTeamsUsed[teamKey].push(player.teams.length);
		// 			teamScores[teamKey] = teamScores[teamKey] ?? [];
		// 			teamScores[teamKey].push(player.score);
		// 			totalTeams++;
					for (const student of [
						...team.strikers,
						...team.specials
					]) {
		// 				playerStudents.push(student.getId());
						const studentId = student.getId();
						students[studentId] = student;
		// 				if (student.isAssist) {
		// 					hasAssist = true;
		// 					studentAssistScatter[studentId] = studentAssistScatter[studentId] ?? [];
		// 					studentAssistScatter[studentId].push({x: player.teams.length, y: player.score});
		// 					studentAssistScatter[studentId+"_levelrank"] = studentAssistScatter[studentId+"_levelrank"] ?? [];
		// 					studentAssistScatter[studentId+"_levelrank"].push({x: player.rank, y: student.level});
		// 					studentAssistScatter[studentId+"_scorePosition"] = studentAssistScatter[studentId+"_scorePosition"] ?? [];
		// 					studentAssistScatter[studentId+"_scorePosition"].push({x: i+1, y: player.score});
		// 					assistantCount[student.id] = assistantCount[student.id] ?? {};
		// 					assistantCount[student.id].total = (assistantCount[student.id].total ?? 0) + 1;
		// 					assistantCount[student.id][student.getTier()] = (assistantCount[student.id][student.getTier()] ?? 0) + 1;
		// 					// assistantCount[student.id]["Lv "+student.level] = (assistantCount[student.id]["Lv "+student.level] ?? 0) + 1;
		// 					studentTeamsUsed[studentId] = studentTeamsUsed[studentId] ?? [];
		// 					studentTeamsUsed[studentId].push(player.teams.length);
		// 					studentScores[studentId] = studentScores[studentId] ?? [];
		// 					studentScores[studentId].push(player.score);
		// 				} else 
						if (!student.isEmpty()) {
		// 					studentScatter[studentId] = studentScatter[studentId] ?? [];
		// 					studentScatter[studentId].push({x: player.teams.length, y: player.score});
		// 					studentScatter[studentId+"_levelrank"] = studentScatter[studentId+"_levelrank"] ?? [];
		// 					studentScatter[studentId+"_levelrank"].push({x: player.rank, y: student.level});
		// 					studentScatter[studentId+"_scorePosition"] = studentScatter[studentId+"_scorePosition"] ?? [];
		// 					studentScatter[studentId+"_scorePosition"].push({x: i+1, y: player.score});
							attendanceCount[student.id] = attendanceCount[student.id] ?? {};
							attendanceCount[student.id].total = (attendanceCount[student.id].total ?? 0) + 1;
							attendanceCount[student.id][student.getTier()] = (attendanceCount[student.id][student.getTier()] ?? 0) + 1;
		// 					// attendanceCount[student.id]["Lv "+student.level] = (attendanceCount[student.id]["Lv "+student.level] ?? 0) + 1;
		// 					studentTeamsUsed[studentId] = studentTeamsUsed[studentId] ?? [];
		// 					studentTeamsUsed[studentId].push(player.teams.length);
		// 					studentScores[studentId] = studentScores[studentId] ?? [];
		// 					studentScores[studentId].push(player.score);
						} else if (hasNoEmpty) {
		// 					attendanceCount["EMPTY"] = attendanceCount["EMPTY"] ?? {};
		// 					attendanceCount["EMPTY"].total = (attendanceCount["EMPTY"].total ?? 0) + 1;
		// 					studentTeamsUsed[studentId] = studentTeamsUsed[studentId] ?? [];
		// 					studentTeamsUsed[studentId].push(player.teams.length);
		// 					studentScores[studentId] = studentScores[studentId] ?? [];
		// 					studentScores[studentId].push(player.score);
							hasNoEmpty = false;
						}
					// }
				}
		// 		for (const student of [...new Set(playerStudents)]) {
		// 			senseisPerStudent[student] = senseisPerStudent[student] ?? {};
		// 			senseisPerStudent[student].total = (senseisPerStudent[student]?.total??0)+1
		// 		}
		// 		if (!hasAssist) {
		// 			assistantCount["EMPTY"] = (assistantCount["EMPTY"] ?? 0) + 1;
		// 		}
			}
			const studentList = Object.values(students);
			// const teamKeyList = Object.keys(teams);
			const participationReport:RaidStudentReportItem[] = [];
		// 	const teamsReport:RaidTeamReportItem[] = [];
		// 	RaidTeamReportItem.totalTeams = totalTeams;
		// 	const filterWithoutStudent = (id) => {
		// 		const teams:number[] = [];
		// 		const score:number[] = [];
		// 		for (const data of displayedData) {
		// 			if (!data.findStudentById(id)) {
		// 				score.push(data.score);
		// 				teams.push(data.teams.length);
		// 			}
		// 		}

		// 		return [score, teams];
		// 	}
			for (const student of studentList) {
				const id = student.getId();
				// const [scoreWithout, teamsWithout] = filterWithoutStudent(id);
				participationReport.push(new RaidStudentReportItem(
					student,
					attendanceCount[id] ?? {total:0},
					// assistantCount[id] ?? 
					{total:0},
					// senseisPerStudent[id] ?? 
					{total:0},
					// studentScatter[id] ? [
					// 	{label: "Score vs Unit # Found in", data: studentScatter[id+"_scorePosition"]},
					// 	{label: "Score vs Units", data: studentScatter[id]},
					// 	{label: "Level vs Rank", data: studentScatter[id+"_levelrank"]},
					// ] : 
					[],
					// studentAssistScatter[id] ? [
					// 	{label: "Score vs Unit # Found in (Assist)", data: studentAssistScatter[id+"_scorePosition"]},
					// 	{label: "Score vs Units (Assist)", data: studentAssistScatter[id]},
					// 	{label: "Level vs Rank (Assist)", data: studentAssistScatter[id+"_levelrank"]},
					// ] : 
					[],
				)
				// .calculateScoreData(studentScores[id], scoreWithout)
				// .calculateTeamData(studentTeamsUsed[id], teamsWithout)
				);
			}

		// 	for (const key of teamKeyList) {
		// 		teamsReport.push(new RaidTeamReportItem(
		// 			key,
		// 			teams[key],
		// 			teamCount[key]
		// 		)
		// 		.calculateScoreData(teamScores[key], [])
		// 		.calculateTeamData(teamTeamsUsed[key], [])
		// 		)
		// 	}
			

			participationReport.sort((a,b) => b.assist.total + b.attendance.total - (a.assist.total + a.attendance.total)).sort((a,b) => b.senseis.total - a.senseis.total);
		// 	teamsReport.sort((a,b) => b.count - a.count);
			setDisplayedParticipationReport(participationReport);
		// 	setStudentDataReport([...participationReport]
		// 		.filter((a)=>a.score.meanWithout>0)
		// 		.sort((a,b) => (b.score.meanWith - b.score.meanWithout) - ((a.score.meanWith - a.score.meanWithout)))
		// 	)
		// 	setTeamReport(teamsReport);
		// } 
		// processRaidReport();
		setIsReportVisible(true);
	}
	
	const handleSaveFile = async () => {
		const fileName = `${server}_${season}_export.tsv`;

		// const textRef = useRef<string>(); // Store your large text here
		// Create a Blob as a stream
		const textBlob = new Blob(getExportData(), { type: 'text/plain' });
		
		// Create a ReadableStream from the Blob
		const textStream = textBlob.stream();
		
		// Read the stream into a Uint8Array
		const chunks: Uint8Array[] = [];
		const reader = textStream.getReader();
		
		while (true) {
			const { done, value } = await reader.read();
			
			if (done) {
				break;
			}
			
			chunks.push(value);
		};
		// Concatenate Uint8Array chunks into one
		const combinedUint8Array = new Uint8Array(chunks.reduce((acc, chunk) => acc.concat(Array.from(chunk)), []));
	
		// Create a Blob from the Uint8Array
		const streamBlob = new Blob([combinedUint8Array], { type: 'application/octet-stream' });
	
		// Trigger the download link
		saveAs(streamBlob, fileName);
	}
// TODO: Move Modal to a different view instead but undfer same component
	return (
		<>
		<WarningMessage />
		{(server.toUpperCase() !== "AS" && server.toUpperCase() !== "JP")  && <Alert variant="warning" dismissible>
			I was not the one who collected data for these. Don't hold me accountable for mistakes here.
			</Alert>}
		<RaidReportModal compact={compact} show={isReportVisible} setShow={setIsReportVisible} reports={{
				attendance: displayedParticipationReport,
				team: teamReport,
				raid: scoreReport,
				studentStatistics: studentDataReport
			}} date={floor} senseis={displayedData?.length ?? 0} fullUnits={true} />
		<Row>
			<Col>
				<RaidTerrainImage name={raidName} terrain={keys[0]?.split("_")[1] ?? ""}>
					<Row>
						<Col md={2}>
							<label>Server</label>
						</Col>
						<Col>
							{server.toUpperCase()}
						</Col>
					</Row>
					<Row>
						<Col md={2}>
							<label>Season</label>
						</Col>
						<Col>
							<a href={`/Tower/${server}/${parseInt(season)-1}`}>&lt;&lt;</a>
							{info.season}
							<a href={`/Tower/${server}/${parseInt(season)+1}`}>&gt;&gt;</a>
						</Col>
					</Row>
					<Row>
						<Col md={2}>
							<label>Attack Type</label>
						</Col>
						<Col>
							<TagBadge>{info.atk}</TagBadge>
						</Col>
					</Row>
					<Row>
						<Col md={2}>
							<label>Defense Type</label>
						</Col>
						<Col>
							<TagBadge>{info.def}</TagBadge>
						</Col>
					</Row>
					<Row>
						<Col md={2}>
							<label>Period</label>
						</Col>
						<Col>
							{info.start} - {info.end}
						</Col>
					</Row>
					<Row>
						<Col md={2}>
							<label>Data Source</label>
						</Col>
						<Col>
							<TowerFloorFileListDropDown onChange={handleChange}></TowerFloorFileListDropDown>
						</Col>
					</Row>
					
					<Row style={style.filterRow}>
						<Col md={2}>
							<label>Include</label>
						</Col>
						<Col>
							{filters?.includeStudents?.map((student, index)=>(
								<StudentFilterItem 
								excludeField={["Assist", "Unit"]}
								key={index}
								isAdvanced={isAdvanced}
								bg="violet"
								className="list-item student include"
								student={student}
								id={index}
								onClose={()=>{
									filters.includeStudents.splice(index, 1);
									setFilters({...filters});
								}}></StudentFilterItem>
							))}

							<MultiStudentPicker 
								setTarget={(toSet:StudentFilter[]) => {
									setFilters({...filters, includeStudents: toSet ?? []});
								}} 
								target={filters.includeStudents}
								pop={() => {
									const r =filters.includeStudents.pop()
									setFilters({...filters});

									return r;
								}}
								push={(toAdd:string) => {
									const studentFilter = new StudentFilter(toAdd);
									filters.includeStudents.push(studentFilter);
									filters.includeStudents = [...new Set(filters.includeStudents)];
									setFilters({...filters});
								}}
							/>
						</Col>
					</Row>
					<Row style={style.filterRow}>
						<Col md={2}>
							<label>Exclude</label>
						</Col>
						<Col>
							{filters?.excludeStudents?.map((student, index)=>(
								<StudentFilterItem 
								key={index}
								excludeField={["Assist", "Unit"]}
								bg="pink-border"
								isAdvanced={isAdvanced}
								className="list-item student badge-outlined"
								student={student}
								id={index}
								onClose={()=>{
									filters.excludeStudents.splice(index, 1);
									setFilters({...filters});
								}}></StudentFilterItem>
							))}
							<MultiStudentPicker 
								setTarget={(toSet:StudentFilter[]) => {
									setFilters({...filters, excludeStudents: toSet ?? []});
								}} 
								target={filters.excludeStudents}
								pop={() => {
									const r =filters.excludeStudents.pop()
									setFilters({...filters});

									return r;
								}}
								push={(toAdd:string) => {
									const studentFilter = new StudentFilter(toAdd);
									filters.excludeStudents.push(studentFilter);
									// filters.excludeStudents = [...new Set(filters.excludeStudents)];
									setFilters({...filters});
								}}
							/>
						</Col>
					</Row>
					<Row style={style.filterRow}>
						<Col md={2}>
						</Col>
						<Col>
							<div key="advanced-student-filter-switch" className="mb-3">
								<Form.Check 
								type="switch"
								checked={isAdvanced}
								id="advanced-student-filter-switch"
								label="Show Advanced Student Options? (This is ugly. Applied even if Hidden)"
								onChange={(a)=>{
									localStorage.setItem(IS_ADVANCED, a.target.checked ? "true" : "false");
									setIsAdvanced(a.target.checked);
								}}
								/>
							</div>
						</Col>
					</Row>
					{/* <Row>
						<Col md={2}>
						</Col>
						<Col>
							<div key="invert-filter" className="mb-3">
								<Form.Check 
								checked={isFilterInverted}
								type="switch"
								id="invert-filter"
								label="Invert Filter Results?"
								onChange={(a)=>{
									setisFilterInverted(a.target.checked);
								}}
								/>
							</div>
						</Col>
					</Row> */}
					<Row>
						<Col md={2}>
						</Col>
						<Col>
							<div key="student-compact" className="mb-3">
								<Form.Check 
								checked={compact}
								type="switch"
								id="student-compact"
								label="Compact Mode"
								onChange={(a)=>{
									setIsCompact(a.target.checked);
								}}
								/>
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							{/* <Button variant="secondary" style={style.button} onClick={handleSaveFile} disabled={!!!displayedData?.length}>
								Export
							</Button> */}
							<Button variant="secondary" style={style.button} onClick={()=>{
								setDisplayedData(filteredData());
							}}>
								Apply Filters
							</Button>
							<Button variant="secondary" style={style.button} onClick={generateAnalytics} 
							disabled={isLoading || (displayedData?.length == 0)}
							// disabled
							>
								Generate Analytics
							</Button>
							
						</Col>
					</Row>
				</RaidTerrainImage>
			</Col>
		</Row>
		<Row hidden={isLoading || isReportVisible}>
			<Col>
				<MyPagination params={{compact: compact}} RowAs={TowerFloorRowRenderer} data={displayedData ?? []} itemsPerPage={20}></MyPagination>
			</Col>
		</Row>
		<Row  style={{alignContent: "center", marginLeft: "1em", marginTop: "1em"}}>
			<Col>
				<MyLoadingSpinner isLoading={isLoading} size={100}/>
			</Col>
		</Row>
		</>
	)
}