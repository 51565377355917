import MessageBody from 'Components/MessageBody';
import IStyle from 'Interfaces/IStyle';
import * as React from 'react';
import { useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';

interface IProps {

}

const styles: IStyle = {
	modal: {
		// maxHeight: "80%"
	}
}

const VERSION = 19;
const USER_VERSION = 'midokuni.message.global.previous';
export function Message(props: IProps) {
	const lastView = parseInt(localStorage.getItem(USER_VERSION) ?? "0");
	// const [show, setShow] = useState(true);
	const [show, setShow] = useState(VERSION !== lastView);
	if (!show) return null;
	return (
		<Modal style={styles.modal} scrollable show={show} dismissible onHide={()=>{
			setShow(false);
			localStorage.setItem(USER_VERSION, VERSION.toString());
		}} >			
			<Modal.Header closeButton>
				<Modal.Title className="text-pink">Midokuni's Message Board</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row style={{
					margin: "0.5em",
					padding: "0.5em"
				}}>
					<Col>
						<h1 className="text-pink">There's a close button for this modal on the upper right.</h1>
						<p>I am going to leave this notice on top from now on.</p>
					</Col>
				</Row>
				<MessageBody />
			</Modal.Body>
		</Modal>
	);
}
